//import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
//import Divider from '@mui/material/Divider';
//import { ThemeProvider, createTheme } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MailIcon from '@mui/icons-material/Mail';

//import AppAppBar from './components/AppAppBar';
//import Hero from './components/Hero';
//import LogoCollection from './components/LogoCollection';
//import Highlights from './components/Highlights';
//import Pricing from './components/Pricing';
//import Features from './components/Features';
//import Testimonials from './components/Testimonials';
//import FAQ from './components/FAQ';
//import Footer from './components/Footer';
//import getLPTheme from './getLPTheme';

function ToggleCustomTheme({ showCustomTheme, toggleCustomTheme }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100dvw',
        position: 'fixed',
        bottom: 24,
      }}
    >
      <ToggleButtonGroup
        color="primary"
        exclusive
        value={showCustomTheme}
        onChange={toggleCustomTheme}
        aria-label="Platform"
        sx={{
          backgroundColor: 'background.default',
          '& .Mui-selected': {
            pointerEvents: 'none',
          },
        }}
      >
        <ToggleButton value>
          <AutoAwesomeRoundedIcon sx={{ fontSize: '20px', mr: 1 }} />
          Custom theme
        </ToggleButton>
        <ToggleButton value={false}>Material Design 2</ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
}

ToggleCustomTheme.propTypes = {
  showCustomTheme: PropTypes.shape({
    valueOf: PropTypes.func.isRequired,
  }).isRequired,
  toggleCustomTheme: PropTypes.func.isRequired,
};

export default function LandingPage() {
  //const [mode, setMode] = React.useState('light');
  //const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  //const LPtheme = createTheme(getLPTheme(mode));
  //const defaultTheme = createTheme({ palette: { mode } });
  //const defaultTheme = createTheme({ palette: { {'dark'} } });
  //const toggleColorMode = () => {
  //  setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  //};

  //const toggleCustomTheme = () => {
  //  setShowCustomTheme((prev) => !prev);
  //};

  /*return (
    <ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Hero />
      <Box sx={{ bgcolor: 'background.default' }}>
        <LogoCollection />
        <Features />
        <Divider />
        <Testimonials />
        <Divider />
        <Highlights />
        <Divider />
        <Pricing />
        <Divider />
        <FAQ />
        <Divider />
        <Footer />
      </Box>
      <ToggleCustomTheme
        showCustomTheme={showCustomTheme}
        toggleCustomTheme={toggleCustomTheme}
      />
    </ThemeProvider>
  );*/
  const logo = '/logo.png';

  return (
    <>
      <CssBaseline />
      <Box sx={{ bgcolor: 'black' }}>
            <img
              style={{maxWidth:'300px',marginTop:'100px',display:'block',marginLeft:'auto',marginRight:'auto',width:'50%'}}
              src={logo}
            />
      </Box>
      <Box sx={{ bgcolor: 'black' }}>
          <Typography align='center'>
            <Button sx={{textTransform:'none'}} variant="contained" color="success" startIcon={<MailIcon />} style={{marginTop:'200px'}} href={'mailto:mail@420dresden.farm'} target={'_blank'} rel={"noreferrer"}>mail@420dresden.farm</Button>
          </Typography>
          <Typography align='center'>
            <Button sx={{textTransform:'none'}} variant="contained" color="success" startIcon={<img width={20} height={20} src={'Telegram_logo.svg'}></img>} style={{marginTop:'10px'}} href={'https://t.me/fourtwentydresden'} target={'_blank'} rel={"noreferrer"}>@fourtwentydresden</Button>
          </Typography>
      </Box>
    </>
  );
}

var appDiv = document.createElement('div');
document.body.appendChild(appDiv);
ReactDOM.createRoot(appDiv).render(<LandingPage/>);
